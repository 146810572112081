import request from './ability/request'
const { VUE_APP_SITEID } = process.env 
console.log(process.env)

const api = {
  // 获取栏目列表
  getChannelsList: () =>{
    let url = `v1/channels/${VUE_APP_SITEID}`
    return request.get(url)
  },
  // 获取栏目
  getChannel: data =>{
    let url = `v1/channels/${VUE_APP_SITEID}/${data.channelId}`
    return request.get(url, data)
  },
  // 获取内容列表
  getContentsList: data =>{
    let url = `v1/contents`
    return request.post(url, {
      ...data,'siteId': VUE_APP_SITEID
    })
  },
  // 获取内容
  getContent: data =>{
    let url = `v1/contents/${VUE_APP_SITEID}/${data.channelId}/${data.contentId}`
    return request.get(url)
  },
  // 提价留言
  leaveMessage: data => {
    let url = `v1/leaveMessage`
    return request.post(url, { ...data })
  }
}
export default api