<template>
  <div style="min-width: 1200px;">
    <!-- 公共头部组件 -->
    <common-header :show="isShowHeader" :fixed="isFixedHeader" :programData="programData"></common-header>
    <div id="app" :style="{ maxWidth: clientWidth+'px' }">
      <router-view :key="$route.fullPath"  />
    </div>
    <global-footer :programData="programData"></global-footer>
    <!-- 悬浮留言按钮 -->
    <slidebar />
  </div>
</template>

<script>
function detectZoom () {
  var ratio = 0,
    screen = window.screen,
    ua = navigator.userAgent.toLowerCase();
  if (window.devicePixelRatio !== undefined) {
    ratio = window.devicePixelRatio;
  }
  else if (~ua.indexOf('msie')) {
    if (screen.deviceXDPI && screen.logicalXDPI) {
      ratio = screen.deviceXDPI / screen.logicalXDPI;
    }
  }
  else if (window.outerWidth !== undefined && window.innerWidth !== undefined) {
    ratio = window.outerWidth / window.innerWidth;
  }
  if (ratio) {
    ratio = Math.round(ratio * 100);
  }
  return ratio;
};
import CommonHeader from '@/components/CommonHeader'
import GlobalFooter from '@/components/GlobalFooter'
import Slidebar from '@/components/Slidebar'
import api from '@/api/index.js'
export default {
  components: {
    CommonHeader,
    GlobalFooter,
    Slidebar
  },  
  name: 'app',
  data () {
    return {
      isShowHeader: true,
      isFixedHeader: true,
      scrollTop: 0, // 页面滚动距离
      clientWidth: 1920,
      programData: [],
    }
  },
  watch: {
    '$route.name'(nVal, oVal) {
      const fixedPage = ['product', 'program']  // 不需要固定头部的页面

      if(fixedPage.includes(nVal)) this.isFixedHeader = false  // 
    }
  },  
  async created() {
    this.clientWidth = window.screen.width
    // const res = await api.getChannelsList()
    const { children } = await api.getChannel({ channelId: 88 })
    this.programData = children
    // document.body.style.zoom = (100 / detectZoom()).toFixed(2)
    // document.getElementById('top-warn-zoom').style.display = 'none'
    // window.onresize = function () {
    //   document.body.style.zoom = (100 / detectZoom()).toFixed(2)
    //   document.getElementById('top-warn-zoom').style.display = 'none'
    // }
  },
  mounted() {
    window.addEventListener('scroll', this.handlePageScroll)
  },
  methods: {
    handlePageScroll() {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      this.isShowHeader = !!(scrollTop < this.scrollTop)
      setTimeout(() => {
        this.scrollTop = scrollTop
      }, 100)
    },
  }
}
</script>

<style lang="less" scoped>
#app {
  font-family: 'Microsoft Yahei', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}
</style>
<style lang="less">
body{
  &::-webkit-scrollbar{ display: none; }
}
</style>
