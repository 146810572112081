import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const RouteView = {
  name: 'RouteView',
  render: h => h('router-view')
}

const router = new VueRouter({
  mode: 'hash',
  routes: [
    {
      path: '/',
      component: RouteView,
      redirect: {
        path: '/home'
      },
      children: [
        // 登录
        {
          name: 'home',
          path: '/home',
          component: resolve => require(['@/views/home.vue'], resolve),
          meta: {
            current: 1
          }
        },
        {
          name: 'product',
          path: '/product',
          component: () => import('@/views/product'),
          meta: {
            title: '产品功能与服务',
            current: 2
          }
        },
        {
          name: 'join',
          path: '/join',
          component: () => import('@/views/join'),
          meta: {
            title: '加入我们',
            current: 6
          }
        },
        {
          name: 'joinDetail',
          path: '/joinDetail',
          component: () => import('@/views/join/detail'),
          meta: {
            title: '岗位信息',
            current: 6
          }
        },
        {
          name: 'about',
          path: '/about',
          component: () => import('@/views/about'),
          meta: {
            title: '关于我们',
            current: 7
          }
        },
        {
          name: 'experienceCenter',
          path: '/experienceCenter',
          component: () => import('@/views/experienceCenter'),
          meta: {
            title: '体验中心',
            current: 7
          }
        },
        // 生态合作
        {
          name: 'cooperation',
          path: '/cooperation',
          component: resolve => require(['@/views/cooperation/cooperation.vue'], resolve),
          meta: {
            current: 5
          }
        },
        // 公司新闻
        {
          name: 'news',
          path: '/news',
          component: resolve => require(['@/views/dynamic/news.vue'], resolve),
          meta: {
            current: 4
          }
        },
        // 行业资讯
        {
          name: 'information',
          path: '/information',
          component: resolve => require(['@/views/dynamic/information.vue'], resolve),
          meta: {
            current: 4
          }
        },
        //方案详情 
        {
          name: 'program',
          path: '/program',
          component: resolve => require(['@/views/program'], resolve),
          meta: {
            current: 3
          }
        },
      ]
    }
  ]
})

// 防止路由重复点击报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

router.afterEach((to, from, next) => {
  document.title = to.meta.title || '安徽海行云物联科技有限公司'
})

export default router