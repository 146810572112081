<template>
  <!-- 公共头部组件 -->
  <transition name="slide">
    <div id="header" class="common-header" :class="{ 'no-fixed': !fixed }" v-show="show">
      <img class="logo" src="@/assets/logo.png">
      <div class="menu">
        <div @click="topage('home')" class="menuItem" :class="{'act': current == 1}">
          首页
          <div class="bline"></div>
        </div>
        <div class="menuItem" :class="{'act': current == 2}">
          产品与服务
          <div class="dmenu hardmenu">
            <div class="hm-leftmenu">
              <div class="hm-lm-item" v-for="lmitem in products" @mouseover="hlmenter(lmitem)" :class="{'act': pcurrent.id==lmitem.id}" :key="lmitem.id">{{lmitem.name}}</div>
            </div>
            <div class="hm-rightmenu">
              <template v-if="pcurrent.children">
                <div v-for="rlitem in pcurrent.children" :key="rlitem.id">
                  <h3>{{rlitem.name}}</h3>
                  <span @click="topage('product', {id: product.url})" v-for="product in productsList[rlitem.id]" :key="product.id">
                    {{product.title}}
                    <div class="tip">
                      <img v-if="product.tips=='new'" src="@/assets/menu/new.png">
                      <img v-if="product.tips=='hot'" src="@/assets/menu/hot.png">
                    </div>
                  </span>
                </div>
              </template>
              <template v-else>
                <div style="padding-top: 20px;">
                  <span @click="topage('product', {id: product.url})" v-for="product in productsList[pcurrent.id]" :key="product.id">
                    {{product.title}}
                    <div class="tip">
                      <img v-if="product.tips=='new'" src="@/assets/menu/new.png">
                      <img v-if="product.tips=='hot'" src="@/assets/menu/hot.png">
                    </div>
                  </span>
                </div>
              </template>
            </div>
          </div>
          <div class="bline"></div>
        </div>
        <div class="menuItem" :class="{'act': current == 3}">
          解决方案
          <div class="dmenu easymenu">
            <div @click="topage('program', {id: program.id})" v-for="program in programData" :key="program.id" class="item">{{program.channelName}}</div>
          </div>
          <div class="bline"></div>
        </div>
        <div class="menuItem" :class="{'act': current == 4}">
          动态资讯
          <div class="dmenu easymenu">
            <div @click="topage('news')" class="item">公司新闻</div>
            <div @click="topage('information')" class="item">行业资讯</div>
          </div>
          <div class="bline"></div>
        </div>
        <div @click="topage('cooperation')" class="menuItem" :class="{'act': current == 5}">
          生态合作
          <div class="bline"></div>
        </div>
        <div @click="topage('join')" class="menuItem" :class="{'act': current == 6}">
          加入我们 
          <div class="bline"></div>
        </div>
        <div class="menuItem" :class="{'act': current == 7}">
          关于我们
          <div class="dmenu easymenu">
            <div @click="topage('about')" class="item">关于我们</div>
            <div @click="topage('experienceCenter')" class="item">体验中心</div>
          </div>
          <div class="bline"></div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import api from '@/api'
export default {
  props: {
    show: { type: Boolean, default: true },
    fixed: { type: Boolean, default: false },
    programData: {type: Array, default: []}
  },
  data() {
    return {
      productsList: {},
      products: [
        {
          'name': '智能制造',
          id: 59,
          children: [
            {
              name: '云APS',
              id: 67
            },
            {
              name: '云MES',
              id: 68
            },
            {
              name: '云IOT',
              id: 69
            },
            {
              name: '云数据',
              id: 271
            },
            // {
            //   name: '云EAM',
            //   id: 70
            // }
          ]
        },
        {
          'name': '智慧物流',
          id: 60,
          children: [
            {
              name: 'LES',
              id: 71
            },
            {
              name: 'TMS',
              id: 72
            },
            {
              name: 'WMS',
              id: 73
            },
          ]
        },
        // {
        //   'name': '数字孪生',
        //   id: 62,
        // },  
        {
          'name': '质量管理',
          id: 61,
        },
        // {
        //   'name': '数字管理',
        //   id: 56,
        //   children: [
        //     {
        //       name: '云ERP',
        //       id: 63
        //     }
        //   ]
        // },
        {
          'name': '智慧办公',
          id: 57,
          children: [
            // {
            //   name: '云OA',
            //   id: 64
            // },
            {
              name: '办公资产平台',
              id: 65
            }
          ]
        },
        // {
        //   'name': '智能研发',
        //   id: 58,
        //   children: [
        //     {
        //       name: '云PDM',
        //       id: 66
        //     }
        //   ]
        // },
      ],
      productsDetailList: [],
      pcurrent: {
        'name': '智能制造',
        id: 59,
        children: [
          {
            name: '云APS',
            id: 67
          },
          {
            name: '云MES',
            id: 68
          },
          {
            name: '云IOT',
            id: 69
          },
          {
            name: '云数据',
            id: 271
          },
          // {
          //   name: '云EAM',
          //   id: 70
          // }
        ]
      },
    }
  },
  name: 'CommonHeader',
  computed: {
    current() {
      return this.$route.meta.current
    }
  },
  async created() {
    const res = await api.getContentsList({
      channelId: 81,
      perPage: 99
    })
    // 重构产品数据
    res.contents.filter(item => item.state === '已审核').forEach(ele => {
      if(this.productsList.hasOwnProperty(ele.channelId)) {
        this.productsList[ele.channelId].push(ele)
      } else {
        this.productsList[ele.channelId] = [ele]
      }
    });
  },
  methods: {
    topage(path,query) {
      // if(query) {
      //   window.open(window.location.href.split('#/')[0]+`#/${path}?id=${query.id}`)
      // } else {
      //   window.open(window.location.href.split('#/')[0]+`#/${path}`)
      // }
      // this.$route.path
      this.$router.push({
        path: `/${path}`,
        query
      })
      scrollToTop()
    },
    hlmenter(item) {
      this.pcurrent = item
    }
  }
}
</script>

<style lang="less" scoped>
* {
  transition: all .3s;
}
.common-header{
  width: 100%;
  min-width: 1200px;
  height: 64px;
	background-color: rgba(31,35,43,.8);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  .logo {
    width: 142px;
    height: 30px;
    margin: 17px 30px;
    float: left;
  }
  .menu {
    height: 64px;
    float: left;
    margin-left: 60px;
    .menuItem {
      width: 120px;
      height: 64px;
      line-height: 64px;
      float: left;
      cursor: pointer;
      font-size: 16px; 
      color: #ffffff;
      text-align: center;
      position: relative;
      .bline {
        display: block;
        width: 16px;
        height: 0px;
        background-color: #ffffff;
        border-radius: 2px;
        position: absolute;
        left: 52px;
        bottom: 0px;
      }
      .dmenu {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        top: 72px;
        background-color: #ffffff;
        color: #000;
        &.easymenu {
          min-width: 120px;
          padding: 8px 12px;
          .item {
            font-size: 16px;
            line-height: 44px;
            word-break: keep-all;
            &:hover {
	            color: #044e9f;
            }
          }
        }
        &.hardmenu {
          width: 528px;
          .hm-leftmenu {
            width: 176px;
            height: 100%;
            min-height: 400px;
            background-color: #f4f5f7;
            float: left;
            .hm-lm-item {
              width: 160px;
              height: 50px;
              line-height: 50px;
              margin: 10px auto;
              font-size: 16px;
              &.act {
                background-color: #044e9f;
                color: #fff;
              }
            }
          }
          .hm-rightmenu {
            text-align: left;
            padding: 0px 32px 14px 32px;
            float: left;
            width: 352px;
            line-height: 32px;
            min-height: 400px;
            background: url('~@/assets/menu/menu-bg.png') right bottom #ffffff no-repeat;
            h3 { 
              font-size: 16px;
              line-height: 32px;
              font-weight: bold;
              font-stretch: normal;
              color: #333333;
              margin: 0;
              margin-top: 20px;
            }
            span {
              display: inline-block;
              font-size: 14px;
              line-height: 21px;
              color: #333333;
              margin-right: 20px;
              position: relative;
              &:hover {
                color: #044e9f;
              }
              .tip {
                position: absolute;
                bottom: 18px;
                right: -20px;
                width: 29px;
                height: 18px;
              }
            }
          }
        }
      }
      &:hover,&.act {
        .bline {
          height: 3px;
          bottom: 2px;
        }
      }
      &:hover {
        .dmenu {
          opacity: 1;
          top: 64px;
          visibility: visible;
        }
      }
    }
  }
}

.no-fixed{
  position: absolute;
}

.slide-enter-active,
.slide-leave-active{
  transition: .8s;
}
.slide-enter,
.slide-leave-to{
  transform: translateY(-100px);
}
</style>