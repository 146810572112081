
// 将url中的参数转为对象；将对象转为url参数形式
import qs from 'qs'
const { VUE_APP_KEY } = process.env 
import axios from 'axios'
// const Token=localStorage.getItem('Authorization')
export function initInstance () {
  // 创建axios实例
  let instance = axios.create({ timeout: 60000 })
  instance.defaults.headers['Content-Type'] = 'application/json; charset=UTF-8'
  // 可以为自定义 axios 实例添加拦截器 request 请求拦截
  instance.interceptors.request.use(
    request => {
      // 请求拦截
      // if (!authorization) storeage.goFirst()
      const { headers = {} } = request
      request.headers = {
        ...headers,
        'X-SS-API-KEY': VUE_APP_KEY
      }

      if (request.method === 'delete') {
        // `paramsSerializer` 是一个负责 `params` 序列化的函数
        request.paramsSerializer = function (params) {
          return qs.stringify(params, { arrayFormat: 'repeat' })
        }
      }
      return request
    },
    error => {
      return Promise.reject(error)
    }
  )
  // 添加响应拦截器
  instance.interceptors.response.use(
    response => {
      // closeLoading()
      const { data } = response
      // if (authorization) storeage.setStore('Authorization', authorization)

      // if (captchatoken) storeage.setStore('CaptchaToken', captchatoken)

      // if (customkey) storeage.setStore('customkey', customkey)

      // if (data && data.errCode === '401') storeage.goFirst()
      // if (typeof data === 'string') data = eval('(' + data + ')')
      return data
    },
    error => {
      // closeLoading()
      // let data = error.response.data
      // let code = error.response && error.response.status
      // if (code === undefined && ((typeof error.message === 'object' && error.message.constructor === Array) && error.message.includes('timeout'))) code = 504
      // if (code) {
      //   if (code === 500 && !storeage.getStore('Authorization')) message.error(codeMessage[505])
      //   else message.error(codeMessage[code])
      // }
      // if (data && data.msg) message.error(data.msg)
      // if (code === 401 || code === 403 || (code === 500 && !storeage.getStore('Authorization'))) storeage.goFirst()
      return Promise.reject(error)
    }
  )
  return instance
}
