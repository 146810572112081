<template>
  <!-- 留言弹窗Form组件 -->
  <div class="message-form-wrap">
    <div class="mask" v-if="!!value && !!mask"></div>
    <transition name="dialog-fade">
      <div class="message-form default" v-if="!!value">
      <div class="header">
        <a-icon class="close" type="close-circle" @click.stop="handleClose"/>
        <p class="title">服务咨询</p>
        <p class="tip">请填写以下信息便于我们为您快速解决</p>
      </div>
      <div class="content">
        <a-form :form="form" @submit="handleSubmit">
          <a-form-item>
            <a-input placeholder="请输入姓名" v-decorator="['userName', decorators.userName]"></a-input>
          </a-form-item>
          <a-form-item>
            <a-input placeholder="请输入手机号" v-decorator="['contactNumber', decorators.contactNumber]"></a-input>
          </a-form-item>
          <a-form-item>
            <a-input 
              :maxLength="150" 
              :showCount="true" 
              class="textarea" 
              placeholder="请输入想咨询的问题" 
              type="textarea" 
              v-decorator="['leaveMessage', decorators.leaveMessage]" 
              @input="getTextCount"
            />
            <span class="text-count">{{ textCount }}/150</span>
          </a-form-item>
          <a-form-item class="sub-btn-box">
            <a-button class="submit-button" type="primary" html-type="submit">提交</a-button>
          </a-form-item>
        </a-form>
      </div>
      <!-- 请求返回轻提示 -->
      <transition name="hint-fade">
        <span class="hint" v-if="isShowHint">{{ hintText }}</span>
      </transition>
      </div>
    </transition>
  </div>

</template>

<script>
import { Form, Button, Input, Icon } from 'ant-design-vue'
import api from '@/api'
export default {
  name: 'MessageForm',
  props: ['value', 'mask'],
  components: {
    [Form.name]: Form,
    [Form.Item.name]: Form.Item,
    [Button.name]: Button,
    [Input.name]: Input, 
    [Icon.name]: Icon,
  },
  data() {
    return{
      timer: null,
      timer1: null,
      isSubmit: false, // 防抖
      isShowHint: false,
      hintText: '留言成功!',
      form: this.$form.createForm(this, { name: 'commit' }),
      decorators: {
        userName: { rules: [{ required: true, message: '请输入您的姓名' }] },
        contactNumber: { 
          rules: [
            { required: true, message: '请输入您的联系方式' },
            { pattern: /^[1]([3-9])[0-9]{9}$/ ,message: '请输入有效的电话号码' }
          ],
          trigger: 'blur' 
        },
        leaveMessage: { rules: [{ required: true, message: '请输入您的留言内容' }] }
      },
      textCount: 0, // 留言字数统计
    }
  },
  methods: {
    // 表单提交
    handleSubmit(e) {
      this.isSubmit = true
      e.preventDefault();
      this.form.validateFields(async (err, values) => {
        if (!err) {
          const { isSuccess, message } = await api.leaveMessage(values)
          this.isSubmit = false
          this.isShowHint = true
          this.hintText = message

          this.timer = setTimeout(() => { // 提示语1.5秒后消失
            if(isSuccess) this.form.resetFields()
            this.isShowHint = false 
          }, 1500)
        }
      })
    },
    // 关闭弹窗
    handleClose() {
      this.form.resetFields()
      this.$emit('close')
    },
    // 监听留言输入，统计字数
    getTextCount() {
      clearTimeout(this.timer1)
      setTimeout(() => {
        this.textCount = this.form.getFieldValue('leaveMessage').length || 0
      }, 400)
    }
  },
  destroyed() {
    clearTimeout(this.timer)
    clearTimeout(this.timer1)
  }
}
</script>

<style lang="less" scoped>
  @import url('~@/styles/animate.less');

@color1: #044e9f;
@color2: #31649d;
 // 遮罩层样式
 .mask{
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(#000,.4);
    z-index: 1000;
  }

.message-form{
  min-width: 300px;
  z-index: 1000;
  .header{
    color: #fff;
    padding: 20px 20px 6px;
    background: 
          url('~@/assets/common/commit-bg.png') no-repeat right/auto 100%,
          linear-gradient(123deg, #044e9f 0%, #31649d 100%), 
          linear-gradient(#d7d7d7, #d7d7d7);

    .close{
      color: rgba(#fff, .6);
      position: absolute;
      right: 12px;
      top: 20px;
      font-size: 16px;
      &:hover{ color: rgba(#fff, 1); }
    }
    .title{font-size: 18px; margin-bottom: 14px;}
    .tip{ font-size: 14px; }
  }
  .content{
    padding: 18px 18px 8px;
    background-color: #fff;
    /deep/.ant-form-item{
      margin-bottom: 10px;
    }
    /deep/.textarea{
      resize: none;
      height: 92px;
      &::-webkit-scrollbar{ display: none; }
    }
    /deep/.submit-button{
      background-color: @color1;
      border-color: @color1;
      border-radius: 15px;
      width: 80px;
    }
    // 提交按钮居中
    /deep/ .sub-btn-box .ant-form-item-control{ text-align: center; }
    // 字数统计样式控制
    .text-count{
      position: absolute;
      bottom: 0px;
      right: 10px;
      font-size: 12px;
      background: #fff;
      line-height: 100%;
      color: rgba(#909399, 1);
    }
  }
  .hint{
    min-width: 120px;
    padding: 6px 12px;
    border-radius: 4px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(@color1, .8);
    font-size: 14px;
    color: #fff;
    z-index: 1000;
    text-align: center;
  }
}

/* 留言弹窗默认位置 */ 
.default{
  position: fixed;
  width: 500px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 3px 2px rgba(#000, .1);
}
</style>
<style lang="less" scoped>
  /* 弹窗过渡动画 */ 
  .dialog-fade-enter-active {
    animation: dialog-fade-in 0.4s;
  }
  // 离开动画
  .dialog-fade-leave-active {
    animation: dialog-fade-out 0.4s;
  }
  
  @keyframes dialog-fade-in {
    0% {
      transform: translate(-50%, -55%);
      opacity: 0;
    }
    100% {
      transform: translate(-50%, -50%);
      opacity: 1;
    }
  }
  
  @keyframes dialog-fade-out {
    0% {
      transform: translate(-50%, -50%);
      opacity: 1;
    }
    100% {
      transform: translate(-50%, -45%);
      opacity: 0;
    }
  }

  /* 提示语过渡动画 */ 
  .hint-fade-enter-active {
    animation: hint-fade-in 0.3s;
  }
  // 离开动画
  .hint-fade-leave-active {
    animation: hint-fade-in 0.3s reverse;
  }
  @keyframes hint-fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
</style>