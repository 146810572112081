<template>
  <div class="global-footer">
    <div class="link">
      <div class="top">
        <div class="top-item">
          <h3>产品与服务</h3>
          <div class="top-col">
            <div class="link-item" @click="topage('product', {id: item.url})" v-for="(item) in productData" :key="item.id">{{item.title}}</div>
          </div>
        </div>
        <div class="top-item">
          <h3>解决方案</h3>
          <div class="top-col">
            <div class="link-item" @click="topage('program', {id: item.id})" v-for="(item) in programData" :key="item.id">{{item.channelName}}</div>
          </div>
        </div>
      </div>
      <div class="left">
        <div class="left-item">
          <h3>关于我们</h3>
          <div class="left-col">
            <div class="link-item" @click="topage('about')">平台介绍</div>
            <div class="link-item" @click="topage('experienceCenter')">展厅介绍</div>
            <div class="link-item" @click="topage('join')">加入我们</div>
          </div>
        </div>
        <div class="left-item">
          <h3>生态合作</h3>
          <div class="left-col">
            <div class="link-item" @click="topage('cooperation')">加入合作伙伴</div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="code">
          <img src="@/assets/code.png">
        </div>
        <div class="tel right-item">
          <i class="iconfont icon-fuwurexian"></i>
          服务热线：400-9955-639
        </div>
        <div class="addr right-item">
          <i class="iconfont icon-dizhi"></i>
          地址：安徽省芜湖市镜湖区名铸广场B座25-27楼
        </div>
        <div class="time right-item">
          <i class="iconfont icon-fuwushijian"></i>
          服务时间：工作日 08:30-17:00
        </div>
      </div>
    </div>
    <div class="cpr">
      Copyright © 安徽海行云物联科技有限公司版权所有
      <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index" style="display:inline-block;text-decoration:none;height:48px;line-height:48px; color:#6b6c74;margin-left: 10px;"> 皖ICP备 2021012850号-1 </a>
      <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=34020202000650" style="display:inline-block;text-decoration:none;height:48px;line-height:48px;margin-left: 10px;"><img src="@/assets/ba.png" style=""/><p style="float:right;height:48px;line-height:48px;margin: 0px 0px 0px 5px; color:#6b6c74;">皖公网安备 34020202000650号</p></a>
    </div>
  </div>
</template>

<script>
import api from '@/api'
export default {
  name: 'GlobalFooter',
  props: {
    programData: {type: Array, default: []}
  },
  data() {
    return {
      productData: []
    }
  },
  async created() {
    const res = await api.getContentsList({
      channelId: 81,
      perPage: 99
    })
    this.productData = res.contents.filter(item => item.state === '已审核') 
  },
  methods: {
    topage(path,query) {
      // let params = quert.map(obj => {
      //   return ``
      // })
      // if(query) {
      //   window.open(window.location.href.split('#/')[0]+`#/${path}?id=${query.id}`)
      // } else {
      //   window.open(window.location.href.split('#/')[0]+`#/${path}`)
      // }
      this.$router.push({
        path: `/${path}`,
        query
      })
      scrollToTop()
    },
  }
}
</script>

<style lang="less" scoped>
* {
  transition: all .3s;
}
.global-footer {
  width: 100%;
  background-color: #1e202a;
  .link {
    // height: 290px;
    width: 1200px;
    overflow: hidden;
    margin: 0 auto;
    padding: 30px 0;
    .top {
      width: 100%;
      // height: 270px;
      border-bottom: 1px solid #2f3240;
      padding-bottom: 20px;
      margin-bottom: 40px;
      overflow: hidden;
      .top-item {
        // height: 270px;
        width: 100%;
        overflow: hidden;
        margin-bottom: 20px;
        h3 {
          font-size: 18px;
          color: #ffffff;
          float: left;
          line-height: 36px;
        }
        .top-col {
          float: right;
          width: 90%;
          .link-item {
            font-size: 16px;
            width: 190px; 
            line-height: 36px;
            color: #878992;
            cursor: pointer;
            float: left;
            margin-right: 20px;
            &:hover {
              color: #b1b4c3;
            }
          }
        }
      }
    }
    .left {
      float: left;
      height: 150px;
      .left-item {
        float: left;
        height: 270px;
        min-width: 180px;
        h3 {
          font-size: 18px;
          color: #ffffff;
        }
        .left-col {
          float: left;
          width: 180px;
          height: 270px;
          .link-item {
            font-size: 16px;
            line-height: 36px;
            color: #878992;
            cursor: pointer;
            &:hover {
              color: #b1b4c3;
            }
          }
        }
      }
    }

    .right {
      float: right;
      font-size: 16px;
      color: #878992;
      line-height: 40px;
      width: 600px;
      .iconfont {
        margin-right: 15px;
        font-size: 20px;
      }
      .code {
        float: left;
        margin-right: 45px;
        img{
          width: 116px;
          height: 116px;
          margin-top: 0 px;
        }
      }
    }
  }
  .cpr {
    border-top: 1px solid #2f3240;
    font-size: 14px;
    height: 48px;
    width: 100%;
    line-height: 48px;
    color: #6b6c74;
    // width: 1200px;
    // margin: 0 auto;
    text-align: center;
  }
}
</style>