import 'babel-polyfill';

import Vue from 'vue'
import App from './App.vue'
import { Layout, Menu,Table,Icon } from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import router from './router'
import './assets/style/base.less'

Vue.use(Layout)
Vue.use(Menu)
Vue.use(Table)
Vue.use(Icon)

Vue.prototype.$IMG_BASE_URL = process.env.VUE_APP_IMG_URL
new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
