import * as instanceMode from './interceptors'
const { VUE_APP_API } = process.env 
const instance = instanceMode.initInstance()
// 去除首尾空格
const delSpace = (params)=>{
  for (var key in params) {
    if (params[key] && typeof params[key] === 'string') {
      if (typeof params === 'string') break
      params[key] = params[key].replace(/(^\s*)|(\s*$)/g, '')
    }
  }
  return params
}
export default {
  // post请求
  post (url, params, config) {
    // 去除首尾空格
    params=delSpace(params)
    url = `${VUE_APP_API}/${url}`
    return instance.post(url, params, config)
  },
  // get请求
  get (url, params, config) {
    url = `${VUE_APP_API}/${url}`
    url = `${url}`
    let paramsObj = {}
    // 去除首尾空格
    params=delSpace(params)
    if (config === 'pic') {
      paramsObj = { params: params, responseType: 'arraybuffer' }
    } else paramsObj = { params: params }
    return instance.get(url, paramsObj, config)
  },
  // patch请求
  patch (url, params, config) {
    url = `${VUE_APP_API}/${url}`
    // 去除首尾空格
    params=delSpace(params)
    return instance.patch(url, params, config)
  },
  // patch请求
  delete (url, params) {
    url = `${VUE_APP_API}/${url}`
    // 去除首尾空格
    params=delSpace(params)
    return instance.delete(url, { params })
  },
  
}
