<template>
  <!-- 服务咨询固定按钮 -->
   <div class="slidebar">
      <div class="baritem commit" @click.stop="isShowCommitP=true;isShowCodeP=false" :class="isShowCommitP?'active':''">
        <i class="iconfont icon-guanwangicon_fuwuzixun"></i>
        <div class="popup commit-popup" v-show="isShowCommitP">
          <message-form :value="true" @close="handleClose('commit')"></message-form>
        </div>
      </div>
      <div class="line"></div>
      <div class="baritem code" @click.stop="isShowCodeP=true;isShowCommitP=false" :class="isShowCodeP?'active':''">
        <i class="iconfont icon-guanwangicon_zhantingyuyue"></i>
        <div class="popup code-popup" v-show="isShowCodeP">
          <div class="header">
              <a-icon class="close" type="close-circle" @click.stop="handleClose('code')"/>
              <p class="title">展厅预约</p>
              <p class="tip">扫码即可预约展厅</p>
            </div>
            <div class="content">
              <div class="code-box">
                <img src="@/assets/code.png" alt="">
              </div>
            </div>
        </div>
      </div>
    </div>
</template>

<script>
import { Icon } from 'ant-design-vue'
import MessageForm from './MessageForm'
export default {
  name: 'Slidebar',
  components: {
    [Icon.name]: Icon,
    MessageForm
  },
  data() {
    return{
      isShowCommitP: false,
      isShowCodeP: false,
    }
  },  
  mounted() {
    // 点击两个弹窗外的区域，关闭所有弹窗
    document.addEventListener('click', (e) => {
      this.isShowCodeP = false
      this.isShowCommitP = false
    })
  },
  methods: {
    handleClose(pName) {
      if(pName === 'commit') {
        this.isShowCommitP = false
      }
      if(pName === 'code') {
        this.isShowCodeP = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
  @color1: #044e9f;
  @color2: #31649d;

  .shadow{
    box-shadow: 0px 0px 3px 2px rgba(#000, .1);
  }
  .before{
    content: '';
    display: block;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    .shadow();
    z-index: -2;
  }
  .after{
    content: '';
    display: block;
    position: absolute;
    width: 30px;
    height: 30px;
    transform: rotate(38deg) skew(344deg);
    right: -2px;
    background-color: #fff;
    top: 50%;
    .shadow();
    z-index: -1;
  }

  .slidebar {
  width: 50px;
  height: 120px;
	background-color: #ffffff;
  position: fixed;
  right: 20px;
  top: 60%;
  border-radius: 25px;
  z-index: 999;
  text-align: center;
  box-shadow: 0px 1px 3px #c8c8c8;
  i.iconfont {
    font-size: 22px;
    line-height: 60px;
  }
  .line {
    width: 24px;
    height: 1px;
    background-color: #e3e3e3;
    margin: 0 auto
  }
  .baritem {
    height: 59px;
    cursor: pointer;
    position: relative;
    &:hover {
      i.iconfont {
        color: @color1;
      }
    }
    &.active{
      i.iconfont{
        color: @color1;
      }
    }
  }
}

/* 留言popup */ 
.commit-popup{
  position: absolute;
  left: -320px;
  top: -225%;
  /deep/.message-form{
    position: absolute;
    text-align: left;
    width: 300px;
    left: auto;
    top: auto;
    transform: none;
    &::before{ .before(); }
    &::after{ .after(); top: 40%; }
  }
}

/* 展厅预约popup */ 
.code-popup{
  position: absolute;
  width: 180px;
  left: -200px;
  bottom: -20px;
  text-align: left;
  .header{
    height: 92px;
    color: #fff;
    padding: 15px 26px 0;
    background: 
          url('~@/assets/common/code-bg.png') no-repeat right/auto 100%,
          linear-gradient(123deg, #044e9f 0%, #31649d 100%), 
          linear-gradient(#d7d7d7, #d7d7d7);
    .close{
      color: rgba(#fff, .6);
      position: absolute;
      right: 12px;
      top: 20px;
      font-size: 16px;
      &:hover{ color: rgba(#fff, 1); }
    }
    p{ margin-bottom: 0; }
    .title{font-size: 18px;}
    .tip{ font-size: 14px; }
  }
  .content{
    padding: 0 2 6px;
    background-color: #fff;
    .code-box{
      width: 128px;
      height: 128px;
      line-height: 128px;
      margin: 0px auto;
      position: relative;
      top: -15px;
      text-align: center;
      background-color: #fff;
      .shadow();
      img{
        width: 115px;
        height: 115px;
      }
    }
  }
  &::before{
    .before();
  }
  &::after{
    .after();
    top: 75%;
  }
}

</style>